export const Experiences = [
    {
        id: 1,
        img: "https://logowik.com/content/uploads/images/123rf9653.jpg",
        role: "Web Application Developer",
        company: "123RF Technology Sdn. Bhd.",
        date: "March 2024 - Present",
        desc: [
            "Executed the integration of a new payment gateway (Tazapay), managing both front-end development and RESTful API implementation while collaborating with peers for optimization.",
            "Researched and implemented third-party APIs, expanding payment options to support global customers and enhance transaction flexibility.",
            "Automated repetitive tasks using scripts, significantly reducing manual workload and streamlining development processes.",
            "Identified, debugged, and resolved critical production issues, minimizing downtime and enhancing user experience.",
            "Developed reusable React components, improving frontend efficiency, maintainability, and UI consistency across the platform.",
        ],
        skills: [
            "PHP",
            "React",
            "Next",
            "Python",
            "AWS"
        ],
        doc: "",
    },
    {
        id: 0,
        img: "https://dbazzar.com/landing_assets/image/Sains_1.png",
        role: "Intern Trainee",
        company: "Sarawak Information Systems Sdn. Bhd. (SAINS)",
        date: "January 2020 - July 2020",
        desc: [
            "Developed a robust CRUD web application adhering to OWASP standards using Bootstrap, PHP, and MySQL in the CodeIgniter framework.",
            "Designed a Python Pandas system for efficient data extraction from XML files to .docs files.",
            "Conducted comprehensive research in computer forensics, mastering forensic tools on Linux and Windows.",
            "Improved a by creating a streamlined PHP7 and MySQL application for managing physical forensics evidence",
        ],
        skills: [
            "Computer Forensics",
            "Ethical Hacking",
            "Python Pandas",
            "CodeIgniter",
            "HTML",
            "CSS",
            "PHP",
            "MySQL",
        ],
        doc: "",
    },
];