import React from "react";
import AboutMeCard from "../Cards/AboutMeCard";

const AboutMe = ({ data }) => {
    return (
        <section id="about" className="about-container">
        <div className="about-wrapper">
            <h2 className="about-title">About Me</h2>
            <p className="about-desc">Here's a peek into what defines me 👋</p>
            <div className="about-content">
                <p className="about-description">{data.description}</p>
                <AboutMeCard about={data} />
            </div>
        </div>
        </section>
    );
};

export default AboutMe;
