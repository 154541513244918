import React, { useState } from 'react';
import { ThemeProvider } from 'styled-components';
import './styles/index.scss';
import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import styled from 'styled-components';
import { darkTheme } from './utils/Themes';
import {
  Navbar,
  Hero,
  AboutMe,
  Skills,
  Experience,
  Education,
  Project,
  ProjectDetails,
  Footer
} from './pages';
import { Bio, about, skills, projects, Experiences, education } from './data';

const Body = styled.div`
  width: 100%;
  overflow-x: hidden;
`;

const Wrapper = styled.div`
  background-image: linear-gradient(180deg, #1B131B 0%, #070707 55%, #1B131B 100%);
`;

const Section = styled.div`
  scroll-snap-align: center;
`;

function App() {
  const [openModal, setOpenModal] = useState({ state: false, project: null });
  return (
    <ThemeProvider theme={darkTheme}>
      <Router>
        <Navbar data={Bio} />
        <Body >
          <Wrapper>
            <Section>
              <Hero data={Bio}/>
            </Section>
            <AboutMe data={about}/>
              <Skills data={skills}/>
              <Experience data={Experiences}/>
            <Project openModal={openModal} setOpenModal={setOpenModal} data={projects}/>
              <Education data={education}/>
              <Footer data={Bio} />
          </Wrapper>
          {openModal.state &&
            <ProjectDetails openModal={openModal} setOpenModal={setOpenModal} />
          }
        </Body>
      </Router>
    </ThemeProvider>
  );
}

export default App;
