export const skills = [
    {
        title: "Frontend",
        skills: [
            {
                name: "ReactJS",
                image: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/react/react-original.svg",
            },
            {
                name: "HTML",
                image: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/html5/html5-original.svg",
            },
            {
                name: "CSS",
                image: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/css3/css3-original.svg",
            },
            {
                name: "JavaScript",
                image: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/javascript/javascript-original.svg",
            },
            {
                name: "TailwindCSS",
                image: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/tailwindcss/tailwindcss-original.svg",
            },
            {
                name: "Bootstrap",
                image: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/bootstrap/bootstrap-original.svg",
            }
        ],
    },
    {
        title: "Backend",
        skills: [
            {
                name: "NodeJS",
                image: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/nodejs/nodejs-original.svg",
            },
            {
                name: "ExpressJS",
                image: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/express/express-original.svg",
            },
            {
                name: "PHP",
                image: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/php/php-original.svg",
            },
            {
                name: "Solidity",
                image: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/solidity/solidity-original.svg",
            },
            {
                name: "MySQL",
                image: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/mysql/mysql-original.svg",
            },
        ],
    },
    {
        title: "Tools & Technologies",
        skills: [
            {
                name: "Power BI",
                image: "https://upload.wikimedia.org/wikipedia/commons/c/cf/New_Power_BI_Logo.svg",
            },
            {
                name: "Tableau",
                image: "https://cdn.worldvectorlogo.com/logos/tableau-software.svg",
            },
            {
                name: "RapidMiner",
                image: "https://storage.scolary.com/storage/file/public/e0acbc45-6523-41a8-821e-24c1b076f8b5.svg",
            },
            {
                name: "Adobe XD",
                image: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/xd/xd-plain.svg",
            },
            {
                name: "Figma",
                image: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/figma/figma-original.svg",
            },
            {
                name: "Unity",
                image: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/unity/unity-original.svg",
            },
            {
                name: "Git",
                image: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/git/git-original.svg",
            },
            {
                name: "VMware Workstation Pro",
                image: "https://upload.wikimedia.org/wikipedia/commons/5/5a/Vmware_workstation_16_icon.svg",
            },
            {
                name: "Hardhat Ethereum VM",
                image: "https://cdn.worldvectorlogo.com/logos/hardhat-seeklogo-com.svg",
            },
            {
                name: "Postman",
                image: "https://cdn.worldvectorlogo.com/logos/postman.svg",
            }
        ],
    },
    {
        title: "Other Tools",
        skills: [
            {
                name: "Adobe Photoshop",
                image: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/photoshop/photoshop-plain.svg",
            },
            {
                name: "Adobe After Effects",
                image: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/aftereffects/aftereffects-original.svg",
            },
            {
                name: "Canva",
                image: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/canva/canva-original.svg",
            },
        ],
    },
];