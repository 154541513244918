import React from 'react';

const AboutMeCard = ({ about }) => {
  return (
    <div className="about-card-container">
      {about.skills.map((skill, index) => (
        <div key={index} className="about-card-section">
          <div className="about-card-lottie">
            <dotlottie-player
              src={skill.lottie}
              speed="0.5"
              className="about-card-lottie-player"
              loop
              autoplay
            ></dotlottie-player>
          </div>
          <div className="about-card-content">
            <h3 className="about-card-name">{skill.name}</h3>
            <p className="about-card-desc">{skill.desc}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default AboutMeCard;
