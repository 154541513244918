import React from "react";
import Typewriter from "typewriter-effect";
import { Resume } from "assets";

// Pre-defined components
import { Button } from "components";

const Hero = ({ data }) => {
  const handleDownload = (event) => {
    event.preventDefault();
    window.open(Resume);
  };

  return (
    <>
      <div id="hero-section">
        <div className="hero-container">
          <div className="hero-inner-container">
            <div className="hero-left-container">
              <div className="hero-title">
                Hi, I am <br />
                {data.fullName}
              </div>
              <div className="hero-text-loop">
                I am a
                <span className="hero-span">
                  <Typewriter
                    options={{
                      strings: data.roles,
                      autoStart: true,
                      loop: true,
                    }}
                  />
                </span>
              </div>
              <div className="hero-subtitle">{data.description}</div>
              <Button
                className="hero-resume-button"
                href={data.socialLinks.resumeUrl}
                onClick={handleDownload}
              >
                Download Resume
              </Button>
            </div>
            <div className="hero-right-container">
              <dotlottie-player
                src="https://lottie.host/335008ff-81ff-4d22-b5bf-57b8015b67ee/VBC7ZC79Zq.lottie"
                background="transparent"
                speed="1"
                style={{ width: "400px", height: "400px" }}
                loop
                autoplay
              ></dotlottie-player>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
