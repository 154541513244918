import React from 'react';
import { Container, Wrapper, Title, Desc, CardContainer } from './ProjectStyle';
import ProjectCard from '../Cards/ProjectCard';

const Projects = ({openModal, setOpenModal, data}) => {
    return (
      <Container id="projects">
        <Wrapper>
          <Title>Projects</Title>
          <Desc>
            Explore some of my projects where I've applied these skills:
          </Desc>

          <CardContainer>
            {data.map((project, index) => (
              <ProjectCard key={index} project={project} openModal={openModal} setOpenModal={setOpenModal}/>
            ))}
          </CardContainer>
        </Wrapper>
      </Container>
    )
}

export default Projects;