import React from "react";
import PropTypes from "prop-types";

const Button = (props) => {
    return (
        <a
            className={`button ${props.className}`}
            href={props.href}
            target="_blank"
            rel="noreferrer"
            onClick={props.onClick}
        >
            {props.children}
        </a>
    );
};

Button.propTypes = {
    href: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
};

export default Button;