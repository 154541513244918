import React, { useState } from "react";
import { Link as LinkR } from "react-router-dom";
import { FaBars, FaGithubSquare, FaLinkedin } from "react-icons/fa";
import logo from "../../assets/images/logo.png";

const Navbar = ({ data }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <header className="navbar">
            <div className="navbar-container">
                {/* Logo & Brand */}
                <LinkR to="/" className="navbar-logo">
                    <img
                        src={logo}
                        alt="Myra Anna."
                        className="navbar-logo-img"
                    />
                    <span className="navbar-logo-text">Myra Anna.</span>
                </LinkR>

                {/* Mobile Toggle Icon */}
                <div
                className="navbar-toggle"
                onClick={() => setIsOpen(!isOpen)}
                >
                    <FaBars />
                </div>

                {/* Navigation Links */}
                <nav className={`navbar-menu ${isOpen ? "active" : ""}`}>
                    <a
                        href="#about"
                        className="navbar-link"
                        onClick={() => setIsOpen(false)}
                    >
                        About
                    </a>
                    <a
                        href="#skills"
                        className="navbar-link"
                        onClick={() => setIsOpen(false)}
                    >
                        Skills
                    </a>
                    <a
                        href="#experience"
                        className="navbar-link"
                        onClick={() => setIsOpen(false)}
                    >
                        Experience
                    </a>
                    <a
                        href="#projects"
                        className="navbar-link"
                        onClick={() => setIsOpen(false)}
                    >
                        Projects
                    </a>
                    <a
                        href="#education"
                        className="navbar-link"
                        onClick={() => setIsOpen(false)}
                    >
                        Education
                    </a>
                </nav>

                {/* Social Icons (hidden on mobile) */}
                <div className="navbar-social">
                    <a
                        href={data.socialLinks.githubUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="navbar-social-icon"
                    >
                        <FaGithubSquare />
                    </a>
                    <a
                        href={data.socialLinks.linkedinUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="navbar-social-icon"
                    >
                        <FaLinkedin />
                    </a>
                </div>
            </div>
        </header>
    );
};

export default Navbar;
