export const Bio = {
    firstName: "Myra",
    fullName: "Myra Anna",
    roles: [
        "Software Engineer",
        "Full Stack Developer",
    ],
    description:
    "Hey there! 👋 I'm a software geek 👩‍💻 on a mission to make cool things happen in the tech world! 🌍🚀 With hands-on experience in end-to-end development 💻, I thrive in tackling challenges ⚡ and crafting seamless user experiences 🎯. Consider me a quick learner 🧠, a team player extraordinaire 🤝, and someone who's always up for a fun coding adventure 🎢💡!",
    socialLinks: {
        githubUrl: "https://github.com/myraAnna",
        resumeUrl: "https://drive.google.com/file/d/1U_C6oB8a9Ab3RshKMkMkzg7lF79yj9y0/view?usp=sharing",
        linkedinUrl: "https://www.linkedin.com/in/myra-annatasha-myr98/",
    },
};